.wrapper {
  display: block;
  margin: 3.8rem 0;
}

.label {
  transform: translateY(2.6rem);
  transition: transform 200ms;
}

.moveLableUp {
  transform: translateY(0);
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 0.8rem;
  color: white;
  font-size: 1.6rem;
  background-color: transparent;
  border: 0 none;
  border-bottom: 1px solid white;
  border-radius: 0;
}

.labelError {
  display: block;
  color: red;
}

.select {
  position: relative;
}

.selectElement {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:focus + .fakeSelect {
      outline-width: 2px;
      outline-style: solid;
      outline-color: Highlight;   
  }

  @media (-webkit-min-device-pixel-ratio:0) {
    &:focus + .fakeSelect {
      outline: -webkit-focus-ring-color auto 5px;
      outline-offset: -2px;
    }
  }   
}

.fakeSelect {
  @extend .input;
  color: white;
}
