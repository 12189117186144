.button {
  background-color: #143dad;
  border: 0 none;
  border-bottom: 4px solid darken(#143dad, 20%);
  border-radius: 0.15rem;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0 0.3rem 0.3rem 0;
  padding: 0.7rem 1.4rem;
  position: relative;
  text-align: center;
  transition: border-bottom-color 200ms;

  &:hover {
    border-bottom-color: lighten(#143dad, 20%);
  }
}
