.loading {
  position: fixed;
  top: 0;
  right:  0;
  bottom: 0;
  left: 0;
  background-color: orangered;
  
  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
