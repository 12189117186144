html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0 1.6rem;
  color: #dad5d5;
  font-size: 1.6rem;
  background-color: black;
}

body.stop-transitions * {
  transition: none !important;
}
