.form {
  max-width: 40rem;
  margin: 1rem auto;
  padding: 0 2rem;
  border: 1px solid white;
  border-radius: 0.4rem;
}

.buttonRow {
  margin: 3rem 0;
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
