.wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(./not-found.gif);
  background-position: center;
  background-size: cover;
}

.vert {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.title {
  margin-bottom: 1rem;
  font-size: 8.2rem;
  font-family: 'Neuton', sans-serif;
  text-align: center;
  text-shadow: 2px 2px red;
}

.paragraph {
  margin-bottom: 5rem;
  text-align: center;
}

.navLink {
  color: #ffd700;
  font-size: 2.6rem;
  text-shadow: 1px 1px black;
}
